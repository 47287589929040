
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDetail: {
      default: false,
      type: Boolean
    },
    isFullEmbargo: {
      default: false,
      type: Boolean
    }
  }
})
export default class RatesCheck extends Vue {
  props: any = this.$props;
  get warning() {
    if (this.props.isFullEmbargo) {
      return this.$t('shipmentRates.errorFullEmbargo');
    } else {
      return this.$t('shipmentRates.errorPartialEmbargo');
    }
  }
}
